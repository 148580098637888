import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import Layout from "../components/Layout"
import Button from "../components/Button"
import Seo from "../components/Seo"

export const query = graphql`
  query getBook($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        author
        publisher
        year
        image {
          childImageSharp {
            gatsbyImageData(
              layout: FIXED,
              height: 250,
              width: 160,
            )
          }
          relativePath
        }
      }
      html
    }
  }
`;

const BookPageTemplate = ({ data }) => {
  const {
    frontmatter: {
      title,
      author,
      publisher,
      year,
      image,
    },
    html,
  } = data.markdownRemark;

  return (
    <Layout>
      <Seo
        title={title}
        image={`/images/${image.relativePath}`}
        isArticle={true}
      />
      <div className="grid grid-cols-6 py-6">
        <section className="col-span-6 md:col-start-2 md:col-span-4 px-5 md:p-0">
          <div className="flex flex-col md:flex-row">
            <div className="self-center md:self-start w-min">
              <GatsbyImage
                image={image.childImageSharp.gatsbyImageData}
                alt={`Cover ${title}`}
                className="rounded-xl"
              />
            </div>
            <div className="pl-0 pt-4 md:pl-4 md:pt-0">
              <span className="uppercase pb-2">{publisher}</span>
              <h1>{title}</h1>
              <h3>{author} - ({year})</h3>
              <p className="pt-4" dangerouslySetInnerHTML={{ __html: html }} />
            </div>
          </div>
          <div className="pt-12 text-center">
            <Button to="/piccoli-sollievi/libreria" text="Torna alla libreria" />
          </div>
        </section>
      </div>
    </Layout>
  )
};

export default BookPageTemplate
